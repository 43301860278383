/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import { Box } from 'theme-ui';

import ImagesGrid from '../../molecules/ImagesGrid';
import CmsContentSection from '../../molecules/CmsContent/section';
import PortableText from '../../custom/portableText';

import { IContentSectionsProps, ISection } from './types';
import { getGridItems } from './utils';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';

const imagesDataTypes = ['singleImage', 'twoImages', 'video', 'videoUrl'];

const ContentSections: React.FC<IContentSectionsProps> = ({ items = [] }) => {
  const groupedContentSections = useMemo(() => {
    const grouped = items.reduce((results, section, currIndex, sections) => {
      const prevSectionTypeName = sections[currIndex - 1]?._type;

      if (imagesDataTypes.includes(section._type)) {
        if (!imagesDataTypes.includes(prevSectionTypeName)) {
          results.push({
            _type: 'images',
            _key: section._key,
            data: [section],
          });
        } else {
          results[results.length - 1].data.push(section);
        }
      } else if (section._type === 'effectiveNumbers') {
        if (prevSectionTypeName !== 'effectiveNumbers') {
          results.push({
            _type: section._type,
            _key: section._key,
            data: [section],
          });
        } else {
          results[results.length - 1].data.push(section);
        }
      } else {
        results.push({ ...section, data: [] });
      }

      return results;
    }, [] as ISection[]);

    return grouped;
  }, [items]);

  return (
    <>
      {groupedContentSections.map((section) => (
        <RevealAnimationWrapper key={section._key}>
          <>
            {section._type === 'simpleContent' && (
              <Box m={['0 0 5rem', '', '0 0 10rem']}>
                <PortableText blocks={section._rawBody} />
              </Box>
            )}
            {section._type === 'headingContent' && (
              <Box m={['0 0 5rem', '', '0 0 10rem']}>
                <h4>{section.heading}</h4>
                <PortableText blocks={section._rawBody} />
              </Box>
            )}
            {section._type === 'effectiveNumbers' && (
              <ul className="list">
                {section.data.map((element) => (
                  <li>
                    <h4 className="title">{element.heading}</h4>
                    <h5>{element.text}</h5>
                  </li>
                ))}
              </ul>
            )}
            {section._type === 'images' && (
              <Box m={['5rem 0', '', '10rem 0']}>
                <ImagesGrid items={getGridItems(section.data)} />
              </Box>
            )}
            {section._type === 'awards' && (
              <CmsContentSection
                title={section.heading}
                items={section.awardsList ?? []}
              />
            )}
          </>
        </RevealAnimationWrapper>
      ))}
    </>
  );
};

export default ContentSections;
