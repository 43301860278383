/** @jsx jsx */
import { jsx, Box, Container, Flex, Text } from 'theme-ui';
import { TagsProps } from './types';

const Tags: React.FC<TagsProps> = ({ items }) => (
  <Box variant="tags.wrapper">
    <Container>
      <Flex as="ul" variant="tags.list">
        {items.map((item) => (
          <Flex as="li" key={item} variant="tags.listItem">
            <Text as="span" variant="tags.text">
              {item}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Container>
  </Box>
);

export default Tags;
