/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Container, useThemeUI } from 'theme-ui';
import { CaseStudyBodyProps } from './types';

import HeroBanner from '../../molecules/HeroBanner';
import CmsContent from '../../molecules/CmsContent';
import InfoBanner from '../../organisms/InfoBanner';
import RelatedWork from '../../organisms/RelatedWork';
import Tags from '../../molecules/Tags';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import ContentSections from '../../organisms/ContentSections';
import { RelatedWorkItem } from '../../organisms/RelatedWork/types';
import { InfoBannerItem } from '../../organisms/InfoBanner/types';
import { prepareCaseStudyWork, prepareCaseStudyInfoBanner } from './utils';
import SEO from '../../atoms/SEO';

const CaseStudyBody: React.FC<CaseStudyBodyProps> = ({
  data: { caseStudy },
}) => {
  const context = useThemeUI();
  const { theme } = context;
  const tagsItems = useMemo(() => {
    const tags = caseStudy?.tag?.map(({ name }) => name);
    return tags ?? [];
  }, [caseStudy.tag]);

  const relatedWorkItems = useMemo<RelatedWorkItem[]>(
    () => prepareCaseStudyWork(caseStudy.works),
    [caseStudy.works],
  );

  const infoBanner = useMemo<InfoBannerItem[]>(
    () => prepareCaseStudyInfoBanner(caseStudy.infoBanner),
    [caseStudy.infoBanner],
  );

  const seoTitle = useMemo(
    () =>
      caseStudy.seoTitle
        ? caseStudy.seoTitle
        : `${caseStudy.clientName} - ${caseStudy.title}`,
    [caseStudy],
  );

  const [headerHeightValue, setHeaderHeightValue] = useState(0);

  useEffect(() => {
    const nav = document.querySelector('header');
    const pageWrapper = document.querySelector('.page-wrapper');
    if (nav) {
      const headerHeight = nav.offsetHeight;
      if (headerHeight !== headerHeightValue) {
        setHeaderHeightValue(headerHeight);
      }
    }
    if (pageWrapper) {
      pageWrapper.style.setProperty(
        '--header-height',
        `${headerHeightValue}px`,
      );
    }
  }, [headerHeightValue]);

  return (
    <Box variant="pages.page.pageWrapper" className="page-wrapper">
      <Box variant="pages.page.pageWrapper.content">
        <SEO
          prefix={seoTitle}
          description={caseStudy?.seoDescription}
          imageUrl={caseStudy?.seoImage?.asset?.fluid?.src}
        />
        <Box
          variant="pages.page.heroBannerWrapper"
          className="case-study-hero-wrapper"
          style={{ backgroundColor: theme.colors?.accent }}
        >
          <HeroBanner
            image={{ fluid: caseStudy?.mainImage?.asset?.fluid }}
            video={{
              url: caseStudy?.mainVideo?.asset?.url,
              image: caseStudy?.mainImage?.asset?.fluid,
            }}
          />
        </Box>
        <Box variant="pages.page.heroBannerWrapper.content">
          <RevealAnimationWrapper>
            <Tags items={tagsItems} />
          </RevealAnimationWrapper>
          <Box p={['4.5rem 0 5rem', '', '8rem 0 10rem']} as="article">
            <Container>
              <CmsContent>
                <RevealAnimationWrapper cascade>
                  <h2 className="category">{caseStudy.clientName}</h2>
                  <h1>{caseStudy.title}</h1>
                </RevealAnimationWrapper>
                <ContentSections items={caseStudy.sections} />
              </CmsContent>
            </Container>
          </Box>
          {infoBanner.length > 0 && (
            <RevealAnimationWrapper>
              <InfoBanner items={infoBanner} />
            </RevealAnimationWrapper>
          )}
          <Container pt={['10rem', '10rem', '20rem']}>
            <RevealAnimationWrapper>
              <RelatedWork items={relatedWorkItems} />
            </RevealAnimationWrapper>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseStudyBody;
