/* eslint-disable no-underscore-dangle */
import { ICasyStudy } from '../../../apiTypes';
import { ImagesGridItem } from '../../molecules/ImagesGrid/types';

export const getGridItems = (arr: ICasyStudy['sections']): ImagesGridItem[] =>
  arr
    .map((section, i) => {
      if (section._type === 'singleImage') {
        return [{ key: `main-${i}`, image: section?.mainImage?.asset }];
      }
      if (section._type === 'videoUrl' || section._type === 'video') {
        const url =
          section._type === 'videoUrl'
            ? section?.videoUrl
            : section?.video?.asset?.url;

        const autoPlay = section._type === 'video';
        const image = section?.placeholderImage?.asset?.fluid;
        return [
          {
            key: `main-${i}`,
            video: {
              url,
              image,
              autoPlay,
            },
          },
        ];
      }
      return [
        {
          key: `first-${i}`,
          image: section?.firstImage?.asset,
          small: true,
        },
        {
          key: `second-${i}`,
          image: section?.secondImage?.asset,
          small: true,
        },
      ];
    })
    .flat();
