import React from 'react';
import { AspectRatio } from 'theme-ui';
import Image from '../../atoms/Image';
import Video from '../Video';
import { HeroBannerProps } from './types';

const HeroBanner: React.FC<HeroBannerProps> = ({ image, video }) => (
  <>
    {video?.url ? (
      <Video autoPlay noControls {...video} />
    ) : (
      <>
        {image && (
          <AspectRatio ratio={194 / 109}>
            <Image {...image} />
          </AspectRatio>
        )}
      </>
    )}
  </>
);

export default HeroBanner;
