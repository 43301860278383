import React from 'react';
import { Box, Text } from 'theme-ui';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import { CmsContentSectionProps } from './types';

const CmsContentSection: React.FC<CmsContentSectionProps> = ({
  title,
  items,
}) => (
  <Box variant="cmsContent.section" as="section">
    {!!title && (
      <RevealAnimationWrapper>
        <Text as="h2">{title}</Text>
      </RevealAnimationWrapper>
    )}
    <RevealAnimationWrapper cascade className="content">
      {items.map((item) => (
        <Text as="p" key={item}>
          {item}
        </Text>
      ))}
    </RevealAnimationWrapper>
  </Box>
);

export default CmsContentSection;
