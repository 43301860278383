import React from 'react';
import { Box, Flex } from 'theme-ui';
import Image from '../../atoms/Image';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import Video from '../Video';
import { ImagesGridProps } from './types';

const ImagesGrid: React.FC<ImagesGridProps> = ({ items }) => (
  <Box>
    <Flex as="ul" variant="imagesGrid.wrapper">
      {items.map(({ image, video, key, small }) => (
        <Flex
          as="li"
          {...{ key }}
          variant={small && !video ? 'imagesGrid.itemSmall' : 'imagesGrid.item'}
        >
          <RevealAnimationWrapper>
            <Box>
              {image && !video && (
                <Image {...image} ratio={small ? 1 / 1 : 1552 / 872} />
              )}
              {video && !image && <Video {...video} />}
            </Box>
          </RevealAnimationWrapper>
        </Flex>
      ))}
    </Flex>
  </Box>
);

export default ImagesGrid;
