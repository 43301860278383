/* eslint-disable no-underscore-dangle */
import React from 'react';
import { graphql } from 'gatsby';
import CaseStudyBody from '../components/pages/caseStudy/CaseStudyBody';
import { CaseStudyPageProps } from '../components/pages/caseStudy/types';

const CaseStudyPage: React.FC<CaseStudyPageProps> = (props) => (
  <CaseStudyBody {...props} />
);

export default CaseStudyPage;

export const pageQuery = graphql`
  query($id: String!) {
    caseStudy: sanityWork(id: { eq: $id }) {
      title
      clientName
      seoTitle
      seoDescription
      seoImage {
        asset {
          fluid {
            src
          }
        }
      }
      tag {
        name
      }
      mainImage {
        asset {
          fluid(maxWidth: 1712) {
            ...GatsbySanityImageFluid
          }
        }
      }
      mainVideo {
        asset {
          id
          url
        }
      }
      works {
        clientName
        title
        slug {
          current
        }
        mainImage {
          asset {
            fluid(maxWidth: 772) {
              ...GatsbySanityImageFluid
            }
          }
        }
        darkBackground
      }
      infoBanner {
        link {
          ... on SanityAddressEmail {
            _type
            email
            title
          }
          ... on SanityMenuItem {
            _type
            link
            title
            openNewTab
            nofollow
          }
        }
        quote
        subtitle
        title
      }
      sections {
        ... on SanityEffectiveNumbers {
          _key
          _type
          heading
          text
        }
        ... on SanityHeadingContent {
          _key
          _type
          _rawBody
          heading
        }
        ... on SanitySimpleContent {
          _key
          _type
          _rawBody
        }
        ... on SanitySingleImage {
          _key
          _type
          mainImage {
            asset {
              fluid(maxWidth: 772) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityTwoImages {
          _key
          _type
          firstImage {
            asset {
              fluid(maxWidth: 772) {
                ...GatsbySanityImageFluid
              }
            }
          }
          secondImage {
            asset {
              fluid(maxWidth: 772) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          video {
            asset {
              id
              url
            }
          }
          placeholderImage {
            asset {
              fluid(maxWidth: 1712) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityVideoUrl {
          _key
          _type
          videoUrl
          placeholderImage {
            asset {
              fluid(maxWidth: 1712) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityAwards {
          _key
          _type
          awardsList
          heading
        }
      }
    }
  }
`;
